import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components"
import Title from "../components/Title";
import {Button} from "../styles/Button";

const ServicesList = () => {

  const info = [
    {
      id: 1,
      image: <StaticImage src="../assets/images/bohabo-bags.png" placeholder="none" height={200} quality="100" alt="Bohabo Maišeliai"/>,
      title: "Cornhole Maišeliai",
      text: `Bohabo Cornhole žaidimo maišeliai yra pagaminti iš aukščiausios kokybės medžiagų, yra pritaikyti žaisti pačiomis blogiausiomis oro sąlygomis ir atitinka visus oficialius ACL reikalavimus. Atlikome daug testavimo darbų ir pagaliau pagaminome, tokius cornhole maišelius, kuriais galime didžiuotis.`,
    },
    {
      id: 2,
      image: <StaticImage src="../assets/images/bohabo-lentos.png" placeholder="none" height={200} quality="100" alt="Bohabo Lentos"/>,
      title: "Cornhole Lentos",
      text: `Bohabo Cornhole žaidimo lentos atitinka visus nustatytus ACL reikalavimus ir yra pritaikytos visoms oro sąlygoms. Pagamintos iš aukščiausios kokybės medienos ir preciziškai sukonstruotos taip, kad patirtumėte maksimalų malonumą žaidžiant šį žaidimą su savo draugais, kolegomis ar artimaisiais.`,
    },
    {
      id: 3,
      image: <StaticImage src="../assets/images/bohabo-zaidimas.png" placeholder="none" height={200} quality="100" alt="Bohabo Nuoma"/>,
      title: "Cornhole Nuoma",
      text: `Bohabo Cornhole žaidimo nuoma puikus pasirinkimas jeigu norite išbandyti Bohabo Cornhole žaidimą, bet nedrįstate jo nusipirkti. Kreipkitės į Mus ir už priimtiną kainą mes Jums išnuomosime Bohabo žaidimą tiek laiko, kiek tik panorėsite. Žaidimo taisyklės labai paprastos, todėl gali žaisti tiek vaikai, tiek suaugę!`,
    },
  ];

  return (
      <div>
        <Title title="Cornhole Nuoma"/>
        <ServicesContent>
          {info.map((service)=>{
            return <ServiceCard key={service.id}>
              <ServicesImage>
                {service.image}
              </ServicesImage>
              <div>
                <ServiceH3>{service.title}</ServiceH3>
                <ServiceP>{service.text}</ServiceP>
              </div>
            </ServiceCard>
          })}
          <ServicesButton centered to="/kontaktai" big="true">Susisiekite su Mumis</ServicesButton>
        </ServicesContent>
      </div>
  )

};

export default ServicesList;

const ServicesButton = styled(Button)`
  margin-top: 2rem;
  grid-area: 4;
  
   @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
    grid-area: 2/2;
  }
`;

const ServicesImage = styled.div`
  display: flex;
  padding: 2rem 0;
  margin: auto;
  justify-content: center;
`;

const ServicesContent = styled.div`
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 72px auto;
    
    @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background: ${props => props.theme.light};
  border-radius: 5px;
  padding: 0px 24px 32px;
  height: 100%;
`;

const ServiceH3 = styled.h3`
    color: ${props => props.theme.black};
    text-align: center;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    font-size: clamp(16px,6vw,26px);
    line-height: 1.1;
    margin: 0.5rem 0 1.5rem 0;
`;

const ServiceP = styled.p`
    color: ${props => props.theme.dark};
    font-size: clamp(14px, 3vw, 16px);
    text-align: center;
`;