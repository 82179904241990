import React from "react"
import { Underline } from "../styles/Underline";
import styled from "styled-components"

const Title = ({title}) => {
  return (
      <div className="section-title">
        <SectionHeading>{title || "default title"}</SectionHeading>
        <Underline small center/>
      </div>
  )
}

export default Title

const SectionHeading = styled.h2`
    color: ${props => props.theme.black};
    text-align: center;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    font-size: clamp(32px,6vw,42px);
    line-height: 1.1;
    margin: 0;
`;
