import styled, {css} from "styled-components"
import {motion} from "framer-motion";

export const Underline = styled(motion.div)`
  background: ${props => props.theme.primary};
  width: 5rem;
  height: 4px;
  margin-left: ${({center}) => (center ? 'auto' : '')};
  margin-right: ${({center}) => (center ? 'auto' : '')};
  margin-bottom: ${({small}) => (small ? '1.75rem' : '2rem')};
  margin-top: ${({small}) => (small ? '1rem' : '2rem')};
  
  @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
    height: 3px;
    width: 5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  
  ${props => props.secondary && css`
    background: ${props => props.theme.secondary};
  `}
  
   ${props => props.fluid && css`
    @media screen and (max-width: ${props => props.theme.breakpoints.laptop}) {
        margin-right: auto !important;
        margin-left: auto !important;
      }
  `}
  
`;