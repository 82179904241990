import React from "react"
import Layout from "../components/Layout"
import {PageWrapper, PageContainer} from "../styles/GlobalStyles";
import Seo from "../components/SEO";
import ServicesList from "../constants/services"
import styled from "styled-components";
import {Button} from "../styles/Button";


const AboutGamePage = () => {

    return (
        <Layout>
            <Seo title="Bohabo Cornhole Nuoma - Žaisk Cornhole Lietuvoje"
                 description="Bohabo Cornhole žaidimo įrangos nuoma Lietuvoje. Cornhole nuoma - tai puikus pasirinkimas norintiems išbandyti Bohabo Cornhole žaidimą!"
            />
            <PageWrapper>
                <PageContainer>
                    <ServicesList/>
                    <RulesInfoWrapper>
                        <RulesText>
                            <h3>Kainos</h3>

                            <h5>Žaidimo kaina</h5>
                            <p>4 x žaidimo maišeliai (rožiniai arba žydros spalvos) - <b>60€</b><br/>
                                8 x žaidimo maišeliai (4 rožiniai, 4 žydros spalvos) - <b>90€</b><br/>
                                Žaidimo komplekto kaina su viena lenta - <b>280€</b><br/>
                                Žaidimo komplekto kaina su dviem lentomis - <b>380€</b></p>

                            <h5>Nuomos kaina</h5>
                            <p>Vienos žaidimo lentos komplekto nuoma parai - <b>35€</b><br/>
                                Dviejų žaidimo lentų komplekto nuoma parai - <b>50€</b><br/>
                                (Nuomojant ilgiau negu parai <b>- 50% nuolaida</b> kitoms paroms)</p>

                            <h5>I komplektą įeina:</h5>
                            <p>- Žaidimo lenta(-os),<br/>
                                - 8 x žaidimo maišeliai (4 rožiniai, 4 žydros spalvos),<br/>
                                - Maišelis (susidėti visus žaidimui skirtus maišelius, bei liniją ir taisykles),<br/>
                                - Speciali linija nuo kurios reikia mesti,<br/>
                                - Taisyklės</p>

                            <p>Galite atsiimti <b>Kaune</b><br/>
                                Siuntimas į kitus Lietuvos miestus - <b>8-12€</b></p>

                            <p>Viskas yra pagaminta pagal visus Pro standartus ir iš aukščiausios kokybės medžiagų.
                                Maišeliai yra pagaminti iš skirtingų medžiagų - viena maišelio pusė yra „LIPNI“, o kita
                                - „SLIDI“. Ši maišelio savybė leis Jums išbandyti skirtingas taktikas žaidžiant Cornhole
                                žaidimą. Maišelių svoris ir dydis yra būtent toks su kokiais yra žaidžiama JAV Pro
                                čempionatuose. Žaidimo lentos taip pat atitinka aukščiausius keliamus standartus.
                                Įsigykite arba išsinuomokite Bohabo ir žaiskite aukščiausio lygio Cornhole žaidimą jau
                                dabar.</p>

                            <Button to="/kontaktai" big="true">Susisiekite su Mumis</Button>
                        </RulesText>

                    </RulesInfoWrapper>
                </PageContainer>
            </PageWrapper>
        </Layout>
    )
};

export default AboutGamePage;

const RulesInfoWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin-top: 0rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
      margin-top: 4rem;
  }
`;

const RulesText = styled.div`
    max-width: 750px;
    margin: auto;
`;

// const RulesSvgWrapper = styled.div`
// //   grid-column: 1/-1;
// //   margin-top: 2rem;
// //
// //   @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
// //       margin-top: 3rem;
// //   }
// // `;
// //
// // const RulesImgWrapper = styled.div`
// //   grid-column: 1/-1;
// //   margin-top: 0rem;
// //   margin-bottom: 1.5rem;
// //   @media screen and (min-width: ${props => props.theme.breakpoints.laptop}) {
// //       margin-top: 2rem;
// //       margin-bottom: 2rem;
// //   }
// // `;